import React, { useState, useEffect } from 'react'
import { createContact, discussWithChat, startChat, updatefollowingChat } from '../api/service.js'
import { appLanguage, titleNormalized } from "../select";
import {Langage, LangageError, LangageHair} from "../language";
import Speech from 'speak-tts'
import {  replaceDashesAndUnderscores } from '../utils/helper.js';
import { getIdForm, getRoutine, getShampooAdvice, getSummary } from '../api/service-hair.js';
import { sendRecommendationEmail } from '../api/api-conseil-hair.js';
import { registerCustomer } from '../utils/utils.js';
import {createClientMessage} from "react-chatbot-kit";





const speech = new Speech()
if (speech.hasBrowserSupport()) {
  // console.log("speech synthesis supported")
  speech.init({
    'volume': 1,
    'lang': (appLanguage === 'french' ? 'fr-FR' : 'en-US'),
    'rate': 1,
    'pitch': 1,
    'voice': (appLanguage === 'french' ? 'Google français' : ((titleNormalized === 'new-pharma') ? 'Microsoft Mark - English (United States)' : 'Google US English')),
    'splitSentences': true,
    'listeners': {
      'onvoiceschanged': (voices) => {
        // console.log("Event voiceschanged", voices)
      }
    }
  }).then((data) => {
  }).catch(e => {
    console.error("An error occured while initializing : ", e)
  })
}

const getText = (key) => {
  if(titleNormalized.includes('hair')) {
    return LangageHair[key][appLanguage];
  }
  return Langage[key][appLanguage];
}

const handleVideo = (src, text, time) => {
  let video = document.getElementById('vid');
  // let video_text_span = document.getElementById('vid_text_span');
  let container_video = document.getElementById('container_vid')

  video.src = src
  // video_text_span.style.animation = 'none';
  // void video_text_span.offsetWidth; /* trigger reflow */
  // video_text_span.style.animation = null;
  // video_text_span.textContent = text
  container_video.style.transform = 'translateY(0)';
  video.play();
  setTimeout(() => {
    container_video.style.transform = 'translateY(1000%)';
  }, time)
}
const handleTTS = (message, delay = 500) => {
  if (!localStorage.getItem('MUTE_TTS') || !JSON.parse(localStorage.getItem('MUTE_TTS'))) {
    setTimeout(() => {
      let msg
      message.payload.choices ? msg = message.message + message.payload.choices.map(item => item.text).join(', ') : msg = message.message
      speech.speak({ text: replaceDashesAndUnderscores(msg) });
    }, delay);
  }
}





const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  const states = children.props.children.props.state

  const [step, setStep] = useState(0)

  const input = document.getElementsByClassName("react-chatbot-kit-chat-input")[0];

  const createContactBrevo = async (diag_hair, diag_infos, recommendation, recommendation_lien, listId, langue) => {
    const contact = {
      email: diag_infos.summary.email,
      firstName: diag_infos.summary.prenom,
      lastName: diag_infos.summary.nom,
      recommendation_lien,
      listId,
      langue,
      texte_conseils: recommendation,
      etat_cuirchevelu: diag_hair.summary.cuir_chevelu ? diag_hair.summary.cuir_chevelu.join(', ') : '',
      etat_cheveux: diag_hair.summary.etat ? diag_hair.summary.etat.join(', ') : '',
      longueur_cheveux: diag_hair.summary.longueur,
      type_cheveux: diag_hair.summary.type,
      DATE_DERNIER_DIAG_GYDBEAUTYCOIF: new Date(),
      TEXTE_RECO_SHAMP: null
    }

    const form = {
      etat_cuirchevelu : contact.etat_cuirchevelu,
      etat_cheveux : contact.etat_cheveux,
      longueur_cheveux : contact.longueur_cheveux,
      type_cheveux : contact.type_cheveux
    }

    const advice = await getShampooAdvice({language : langue.slice(0,2), form})

    contact.TEXTE_RECO_SHAMP = advice['shampoo-advice']

    await createContact(contact)
  }


  const generateRoutine = async (id) => {
    let result
    try {
      result = await getSummary(id)

      if (!result.summary)
        throw new Error("Un problème est survenu lors de la recommendation personalisée")

      const obj = {
        "language": (appLanguage.slice(0,2)),
        "summary": result.summary
      }
      result = await getRoutine(obj, 'face')
      result.response = result['care-routine']
      result.success = true
      result.status = 'FINISHED'

    } catch (error) {
      result.success = false
      result.response = error.message
    }
    finally {
      return result
    }
  }

  const preLoadRoutine = async (id) => {
    let result
    try {
      result = await getSummary(id)

      if (!result.summary)
        throw new Error("Un problème est survenu lors de la recommendation personalisée")

      const obj = {
        "language": appLanguage.slice(0,2),
        "summary": result.summary
      }
      result = await getRoutine(obj, 'hair')
      result.response = result['care-routine']
      result.success = true
      result.status = 'FINISHED'

      localStorage.setItem('recoGpt', result)
      setState(prevState => ({
        ...prevState,
        recommendationGPT: result
      }))

    } catch (error) {
      result.success = false
      result.response = error.message
    }
  }
  const ask4Selfie = async (id) => {

    let result = {}
    result.success = true
    if(titleNormalized.includes('hair')) {
      result.response = getText(('take_final_selfie'))
    } else {
      result.response = getText(('start_experience'))
    }
    result.status = 'FINISHED'
    result.options = {
      "widget": "selfieOrChat",
      "delay": 2000
    }

    return result
  }

  const choseTreatments = async (id) => {

    let result = {}
    result.success = true
    result.response = getText('i_choose_type')
    result.status = 'FINISHED'
    result.options = {
      "widget": "myDiagnostic",
      "delay": 2000
    }

    return result
  }

  const ask4Needs = () => {

    let result = {}
    result.success = true
    result.response = Langage.i_will_propose[appLanguage]
    result.status = 'FINISHED'
    result.options = {
      "widget": "specifyNeeds"
    }

    return result

  }

  let lstModule = [discussWithChat, (titleNormalized === 'chatbot-ns' ? choseTreatments : ask4Selfie), ask4Needs, discussWithChat, generateRoutine]
  if (titleNormalized.includes('hair')) {
    lstModule = [discussWithChat, discussWithChat, ask4Selfie, generateRoutine]
  }

  let app; // todo remove after salon
  const pharmaId = (window.location.href.includes('id=') ? window.location.href.split('id=')[1].split('&')[0] : '2085475');
  if (pharmaId === '2087398') {
    app = 'jess';
  } else {
    app = titleNormalized;
  }

  const next = async (message, tmpStep) => {
    let result, id, obj;

    let num = tmpStep ? tmpStep : step  // si un step est fourni a la fonction next il doit primer sur l etat step

    if (num > lstModule.length-1)
      return;

    try {
      loadingPoints()

      switch (num) {
        case 0:
          id = localStorage.getItem("chatIdInfos")
          obj = { "role": 'user', "content": message }
          result = await lstModule[num](id, obj)
          break;
        case 1:
       if(titleNormalized.includes('hair')) {
         id = localStorage.getItem("chatIdHair")
         if(!id){
           result = await startChat('hair', { lang : appLanguage.slice(0,2) })
           id = result.id
           localStorage.setItem("chatIdHair", result.id)
         }
         obj = { "role": 'user', "content": message }
         result = await lstModule[num](id, obj)
       } else {
         result = await lstModule[num](id)
       }
          break;
        case 2:
          result = await lstModule[num](id)
          break;
        case 3:
          if(titleNormalized.includes('hair')) {
            id = localStorage.getItem("chatIdHair")
            result = await lstModule[num](id)
          } else {
            id = localStorage.getItem("chatIdFace")
            if (!id) {
              result = await startChat('face', {lang : appLanguage.slice(0,2) })
              id = result.id
              localStorage.setItem("chatIdFace", result.id)
            }
            obj = { "role": 'user', "content": message }
            result = await lstModule[num](id, obj);
            if(titleNormalized === 'chatbot-ns') {
              setStep(3);
            }
          }
          break;
        case 4:
          id = localStorage.getItem("chatIdFace")
          result = await lstModule[num](id)
          break;
        default:
        // code block
      }
      removeLoadingPoints();

      if (!result || !result.success)
        throw new Error(LangageError['chat_error'][appLanguage]);

        if (result.status === 'FINISHED') {
          setStep(prevStep => prevStep + 1)
        }

      if (result.status === 'RUNNING' || result.status === 'FINISHED' || result.status === 'CANCELLED') {
        createBotMessage(result.response, result.options)
      }



      if (result.status === 'FINISHED' && num === 0) {
        const diag_infos = await getSummary(localStorage.getItem("chatIdInfos"))
        const person = diag_infos.summary
        await updatefollowingChat({ step: true, num_step: 2 }, localStorage.getItem("followingCactusId"))
        const token = await registerCustomer(person.prenom, person.nom, person.email, person.age)
        setState(prevState => ({
          ...prevState,
          token : token.token
        }))
        if(titleNormalized.includes('hair')){
          next(getText('start_diag'), 1)
          return
        }
      }

      if (result.status === 'FINISHED' && lstModule.length === num + 1) {

            let listId, templateId;
              if(appLanguage == 'french'){
                listId = 13
                templateId = 24
              }
              if(appLanguage == 'english'){
                listId = 14
                templateId = 56
              }
           
            const pharmaId = (window.location.href.includes('id=') ? window.location.href.split('id=')[1].split('&')[0] : '2085475');

              let id;
              if(titleNormalized.includes('hair')) {
                id = localStorage.getItem("chatIdHair")
              } else {
                id = localStorage.getItem("chatIdFace")
              }
            const data = await getIdForm(id)
            const advisor = localStorage.getItem("advisor")
            const diag_hair = await getSummary(id)
            const diag_infos  = await getSummary(localStorage.getItem("chatIdInfos"))
            const followingId = localStorage.getItem('followingCactusId')
            const url = `https://democactus.gydtech.io?mail=${diag_infos.summary.email}&&token=${states.token}&idQuestionner=${data.idform}&pharmaId=${pharmaId}&advisor=${advisor}&followingId=${followingId}&lang=${appLanguage}`
            const params = {
              first_name: diag_infos.summary.prenom,
              to: diag_infos.summary.email,
              url,
              templateId
            }
            await updatefollowingChat({step : true, num_step: 3, chat_id : id}, localStorage.getItem("followingCactusId"))
            await createContactBrevo(diag_hair, diag_infos, result.response, url, listId, appLanguage)
            await sendRecommendationEmail(result.response, params)
        if(titleNormalized === 'chatbot-hair') {
          createBotMessage(getText('see_selection'), {widget: 'oneButton', payload:{url, content: getText('see_selection')}})
        } else if(titleNormalized === 'chatbot-hair-ybera') {
          createBotMessage(getText('see_selection'), {widget: 'productCarousel', payload:{url, content: getText('see_selection'), id}})
        } else {
          createBotMessage(getText('your_products'), { widget: 'offers', payload: { url } })
        }
      }

      if (result.status === 'FINISHED' && num + 1 == 1) {
        next(null, 1)
        return
      }
      // if (result.status === 'FINISHED' && num + 1 == 2) {
      //   next(null, 2)
      //   return
      // }
      if (result.status === 'FINISHED' && num + 1 == 4) {
        next(null, 4)
        return
      }
      if(result.status === 'FINISHED' && num == 1 && titleNormalized.includes('hair')){
        next(null, 2)
        return
      }
      // if(result.status === 'FINISHED' && num == 2){
      //   next(null, 3)
      //   return
      // }
    } catch (error) {
      createBotMessage(error.toString());
      removeLoadingPoints();
    }
    // setTimeout(() => {
    //   const lastChild = document.querySelector('.react-chatbot-kit-chat-message-container').lastChild
    //   lastChild.scrollIntoView()
    // }, 3000)

    // const inputBar = document.querySelector('.react-chatbot-kit-chat-input-container');
    // if(question?.widget === 'multiChoices' || question?.widget === 'yesOrNot' )
    //   inputBar.style.display = "none";
    // else
    //   inputBar.style.display = "block";

    // inputBar.blur();
  };


  const createBotMessage = (msg, options = {}) => {
    input.readOnly = false
    const message = createChatBotMessage(
      msg,
      options
    );
    addMessageToState(message)
  };

  const createUserMessage = (msg, options = {}) => {
    input.readOnly = false
    const message = createClientMessage(
        msg,
        options
    );
    addMessageToState(message)
  };


  // gere le style du chargement lors de l emission du message
  const loadingPoints = () => {
    const icon = document.querySelector('.react-chatbot-kit-chat-btn-send-icon');
    const btn = document.querySelector('.react-chatbot-kit-chat-btn-send');
    const loading_point = document.createElement('span');
    const next_point = document.createElement('span');
    loading_point.className = 'loading-points';
    loading_point.append(next_point);
    icon.style.display = 'none';
    btn.append(loading_point);
  }

  const removeLoadingPoints = () => {
    const icon = document.querySelector('.react-chatbot-kit-chat-btn-send-icon');
    const loading_point = document.querySelector('.loading-points');
    icon.style.display = 'block';
    loading_point && loading_point.remove();
    // speechToText();
  }

  const speechToText = () => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

    const form = document.querySelector('.react-chatbot-kit-chat-input-form');
    const btn = document.querySelector('.react-chatbot-kit-chat-btn-send');
    const input = document.querySelector('.react-chatbot-kit-chat-input');
    btn.style.display = 'none';

    const stt = document.getElementById('stt-btn') || document.createElement('button');
    stt.innerHTML = '<img src="assets/images/stt.png">';
    stt.className = 'react-chatbot-kit-chat-btn-send';
    stt.id = 'stt-btn';
    stt.style.display = 'flex';

    form.appendChild(stt);

    stt.addEventListener('click', () => {
      let recognization = new SpeechRecognition();
      recognization.lang = "en-US";
      if(appLanguage === 'french') {
        recognization.lang = "fr-FR"
      }

      recognization.onstart = () => {
        stt.innerHTML = getText('listening');
      }
      recognization.onresult = (e) => {
        const transcript = e.results[0][0].transcript;
        input.value = transcript;
        createUserMessage(transcript)
        next(transcript)
      }
      recognization.onspeechend = () => {
        stt.innerHTML = '<img src="assets/images/stt.png">';
        recognization.stop();
      };
      recognization.start();
      setTimeout(() => {
        stt.innerHTML = '<img src="assets/images/stt.png">';
        recognization.stop();
      }, 5000);
    });
    input.addEventListener("input", (e) => {
      console.log(e.target.value)
      if(e.target.value && e.target.value.length) {
        stt.style.display = 'none';
        btn.style.display = 'flex';
      } else {
        stt.style.display = 'flex';
        btn.style.display = 'none';
      }
    });
  }

  // tient a jour le state du bot en ajoutant au state le dernier message recu
  const addMessageToState = (message) => {
    setState(prevState => ({
      ...prevState,
      messages: [...prevState.messages, message]
    }))
  }

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: { next, createBotMessage, addMessageToState },
        });
      })}
    </div>
  );
};

export default ActionProvider;


