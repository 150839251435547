import React, { useEffect, useState } from 'react'
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import './App.css';
import config from './bot/config.js';
import MessageParser from './bot/MessageParser.js';
import ActionProvider from './bot/ActionProvider.js';
import { getPicture, getProfile, uploadPictureD_id, createfollowingChat, createOrganization, getHairDresser, discussWithChat, startChat } from "./api/service";
import { appLanguage, titleNormalized } from "./select";
import { Langage, LangageHair, AppName } from "./language";
import ResponsiveDialog from './components/popupWelcome/popupWelcome.jsx';

function App() {

  const [idOrganization, setIdOrganization] = useState(null);
  const [advisor, setAdvisor] = useState();
  const [data, setData] = useState(null);

  const handleChangeAdvisor = async (advisor) => {
    setAdvisor(advisor)
    localStorage.setItem('advisor', advisor);
    let res = await createfollowingChat({ idOrganization: idOrganization, chat_id: null, advisor });
    localStorage.setItem('followingCactusId', res.id);
  }

  const getText = (key) => {
    return Langage[key][appLanguage];
  }
  const getTextApp = (key) => {
    return AppName[key][appLanguage];
  }

  const getAppName = (app) => {
    switch (app) {
      case 'chatbot-hair': return getTextApp("chatbot_hair");
      case 'newpharma': return 'New pharma';
      case 'jess': return 'jessica simpson';
      case 'hairdresser-ca': return 'Calculateur';
      case 'chatbot-hair-ybera': return 'Ybera';
      case 'chatbot-ns':
      default: return 'Cactus';
    }
  }

  useEffect(() => {

    const fetchData2 = async () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let id = urlParams.get('id')
      let data
      if(!id)
        id = '2085475'
      try {
          const number = parseInt(id, 10);
          data = await createOrganization({ galien_user_id: number, app: window.location.hostname.split('.')[0], group_id: 4 })
          setIdOrganization(data.id)
          let res = await createfollowingChat({ organization_id: data.id, chat_id: null, advisor });
          localStorage.setItem('followingCactusId', res.id);
      } catch (error) {
        console.error(error)
      }

    }

    fetchData2()
  }, [])

  useEffect(() => {
    localStorage.clear(); // reinitialise le localStorage
    const getIdChat = async () => {
      try {
        const data = await startChat('infos', {lang : appLanguage.slice(0,2) }) // initialize le chat et recupere l id
        if (data.success) {
          const obj = { "role": 'assistant', "content": getText('start') } // a besoin d' une question pour initialiser le chatbot
          await discussWithChat(data.id, obj)
          localStorage.setItem('chatIdInfos', data.id) // stocke l id dans le localStorage
        }
        console.log('chat initialise');
      } catch (error) {
        console.error(error)
      }
    };
    getIdChat()
  }, []);

  const postPicture = async (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        try {
          const result = await uploadPictureD_id(file);
          if (result.result_url) {
            document.getElementById('ai-conf').style.display = 'none';
          }
        }
        catch (e) {

        }
      }
    }
  }

  let avataeImage;
  let id;
  if (window.location.href.includes('id=')) {
    id = window.location.href.split('id=')[1].split('&')[0];
  }

  const getAvatar = async (id) => {
    if (id) {
      try {
        const profile = await getProfile(id);
        document.querySelector('.react-chatbot-kit-chat-header').innerHTML = profile?.name;

        const avatar = await getPicture(profile?.pictures?.idAvatar);
        if (avatar && avatar.data) {
          avataeImage = 'data:image/png;base64,' + avatar.data;
          document.getElementById('avatar-logo').querySelector('img').src = avataeImage;
        }

      } catch {

      }
    }
    // setTimeout(() => {
    //   document.getElementById('avatar-logo').querySelector('img').style.visibility = 'visible';
    // }, id ? 0 : 1000)
  }
  getAvatar(id);



  return (
    <div className="App">
      {/* {(data && data.length > 1) &&
        <ResponsiveDialog
          data={data}
          handleChangeAdvisor={handleChangeAdvisor}
          advisor={advisor}>
        </ResponsiveDialog>} */}
      <Chatbot
        config={config}
        headerText={getAppName(titleNormalized)}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        placeholderText={getText('placeholder')}
        validator={(msg) => msg.length > 0}
      />
      {/*<div id={"ai-con"}>*/}
      {/*<img src="/assets/images/config.png" className={'setting'} onClick={ (e) => {document.getElementById('ai-conf').style.display = 'block'}}/>*/}
      {/*<video src={localStorage.getItem('MY_AI_TALKING_FACE') ? localStorage.getItem('MY_AI_TALKING_FACE') : "/assets/video1.mp4"} autoPlay={true} loop={true} id={"ai-video"}></video>*/}
      {/*<div id={'ai-conf'}>*/}
      {/*    <input accept="image/*"*/}
      {/*           type="file"*/}
      {/*           capture="environment"*/}
      {/*           onChange={ (e) => {postPicture(e.target, 25)}} />*/}
      {/*</div>*/}
      {/*</div>*/}
    </div>
  );
}

export default App;
