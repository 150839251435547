import React, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import './oneButton.css';

const OneButton = (props) => {
    const { setState, payload, actionProvider, checkedProducts } = props;
    const { url, content } = payload;
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsVisible(true);
        }, 2000);

        return () => clearTimeout(timeout);
    }, []);

    const handleClick = () =>{
        
        if(url)
            window.location = `${url}&${checkedProducts.join('/')}`
    }

    return (
        isVisible && (
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    padding: '0 5%',
                }}
            >
                <Button onClick={handleClick}>{content}</Button>
            </Box>
        )
    );
};

export default OneButton;
